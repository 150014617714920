<template>
  <div class="wameed-dashboard-page-content"  v-if="getUserInfo">

    <b-row>

      <b-col cols="12" md="6">

        <b-card
            no-body class=" wameed-card  m-0">
          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('users.client.info') }}
            </h5>
          </b-card-header>

          <div class="person-info">
            <div class="data">


              <img v-if="getUserInfo.image && showImage" :src=getUserInfo.image :alt=getUserInfo.name @error="onError"
                   class="person-img">
              <div v-else class="person-img">
               <span>
                  <d-person-icon/>
               </span>
              </div>

              <div class="col">
                <div class="name">{{ getUserInfo.name }}</div>
                <div class="phone">
                  {{ getUserInfo.orders_count }} {{ $t('common.order') }} -
                  <span class="text-main">{{
                      formatNumber(getUserInfo.total_amount - getUserInfo.total_discount)
                    }}</span>
                </div>
                <div class="mb-1"></div>
                <div class="_row">
                  <span class="_row_title">{{ $t('orders.total_price') }} :</span>
                  <span class="_row_value blue">
                    {{ formatNumber(getUserInfo.total_amount) }}
                  </span>
                </div>

                <div class="_row">
                  <span class="_row_title">{{ $t('orders.total_discount') }} :</span>
                  <span class="_row_value red">
                    {{ formatNumber(getUserInfo.total_discount) }}
                  </span>
                </div>
                <div class="_row ">
                  <span class="_row_title">{{ $t('orders.total') }} :</span>
                  <span class="_row_value green">
                    {{ formatNumber(getUserInfo.total_amount - getUserInfo.total_discount)}}
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div class="px-5 pb-2">

            <div class="divider mb-2"></div>
            <div class="tile-text between mb">
              <div class="text">{{ $t('users.table.phone') }}</div>
              <div class="value">{{ getUserInfo.phone }}</div>
            </div>
            <div class="tile-text between mb">
              <div class="text">{{ $t('users.table.email') }}</div>
              <div class="value">{{ getUserInfo.email }}</div>
            </div>
            <div class="tile-text between mb">
              <div class="text">{{ $t('users.table.gender') }}</div>
              <div class="value">{{ getGender }}</div>
            </div>
            <div class="tile-text between mb">
              <div class="text">{{ $t('users.client.table.account_type') }}</div>
              <div class="value">{{ getAccountType }}</div>
            </div>
            <div class="tile-text between mb" v-if="getUserInfo.addresses.length>0">
              <div class="text">{{ $t('users.table.addresses') }}</div>
              <div class="value link text-main" @click="showAddressesModal=true">{{
                  $t('users.table.show_addresses')
                }}
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <addresses-modal
        :addresses="getUserInfo.addresses"
        :visible="showAddressesModal"
        @close="showAddressesModal = false"
    />
  </div>
</template>

<script>
import AddressesModal from "@/views/pages/users/clients/components/addressesModal";

import {mapGetters} from 'vuex';

export default {

  components: {
    AddressesModal
  },
  data() {
    return {
      showImage: true,
      showAddressesModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserInfo: 'admin/users/detail/getUserInfo',
    }),


    getGender() {
      let gender = this.getUserInfo.gender;
      if (gender === 2)
        return this.$i18n.t('common.unregister');
      if (gender === 1)
        return this.$i18n.t('common.male')

      return this.$i18n.t('common.female')
    },

    getAccountType() {
      let accountType = this.getUserInfo.account_type;
      if (accountType === 1)
        return this.$i18n.t('account_type.personal')
      if (accountType === 2)
        return this.$i18n.t('account_type.company')

      return this.$i18n.t('account_type.personal')
    }
  },
  async created() {
    this.$store.dispatch('admin/users/detail/unsetData');
    await this.$store.dispatch('admin/users/detail/info', {
      id: this.$route.params.id,
      type: 'clients'
    })
  },
  methods: {
    onError() {
      this.showImage = false;
    },


  },
};
</script>
